import {View, FlatList, RefreshControl, ActivityIndicator} from 'react-native';
import React from 'react';
import {style} from './ProfileList.styles';
import {Breakpoints, Colors} from '../../../utils/theme';
import {useDimensions} from '../../../hooks/useDimensions';
import {
  EmptyComponent,
  MatrimonialProfileInfo,
  Spinner,
} from '../../../components';
import {useProfileListController} from './ProfileList.controller';
import {MatrimonialProfile} from '../../../assets/svg';
import {NavigationNames} from '../../../utils/routeNames';
import {useNavigate} from 'react-router-dom';
import {FamilyMembers} from '../../../services/api/response/user';
import {FormattedMessage} from 'react-intl';
import {useTranslation} from 'react-i18next';

const ProfileList: React.FC = () => {
  const {windowWidth} = useDimensions();
  const navigate = useNavigate();
  const isMobile =
    windowWidth <= Breakpoints.md && windowWidth >= Breakpoints.xs;
  const columnCount = isMobile ? 2 : Math.floor(windowWidth / 300);
  const {loading, matrimonialProfiles, fetchMatrimonialProfiles} =
    useProfileListController();
  const {t} = useTranslation();

  const onProfilePress = (item: Omit<FamilyMembers, 'relationship'>): void => {
    navigate(NavigationNames.FamilyMemberDetails, {
      state: {member: item},
    });
  };
  return (
    <View style={style.container}>
      <Spinner visible={loading.fetching} />
      <FlatList
        key={columnCount}
        data={matrimonialProfiles}
        numColumns={columnCount}
        keyExtractor={item => item.familyMemberId.toString()}
        renderItem={({item}) => (
          <MatrimonialProfileInfo
            {...item}
            onPress={() => onProfilePress(item)}
          />
        )}
        columnWrapperStyle={style.columnWrapperStyle}
        contentContainerStyle={style.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={loading.refreshing}
            onRefresh={() => fetchMatrimonialProfiles(0, true)}
          />
        }
        onEndReachedThreshold={0.5}
        onEndReached={({distanceFromEnd}) => {
          if (
            distanceFromEnd > 10 &&
            matrimonialProfiles.length < loading.dataTotalCount
          ) {
            fetchMatrimonialProfiles(matrimonialProfiles.length, false, true);
          }
        }}
        ListFooterComponent={() => (
          <View>
            {loading.isLoadMore && (
              <ActivityIndicator size="large" color={Colors.primary} />
            )}
          </View>
        )}
        ListEmptyComponent={() => (
          <EmptyComponent
            icon={<MatrimonialProfile width={250} height={250} />}
            isFetching={loading.fetching}
            message={<FormattedMessage id={t('matrimonial.ComingSoon')} />}
          />
        )}
      />
      {/* <FloatingButton
        onPress={() => navigate(NavigationNames.AddMatrimonialProfile)}>
        <Pulse />
      </FloatingButton> */}
    </View>
  );
};

export default ProfileList;
