import {StyleSheet, Platform} from 'react-native';
import {Colors} from '../../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  contentContainerStyle: {
    flexGrow: 1,
    paddingBottom: 30,
    paddingHorizontal: 16,
  },
  columnWrapperStyle: {
    paddingVertical: 10,
    gap: 15,
  },
  input: {
    margin: 'auto',
    marginBottom: 20,
    width: Platform.OS === 'web' ? '100%' : '90%',
    borderWidth: 1,
    borderColor: Colors.maroon,
    borderRadius: 5,
    padding: 10,
  },
});
