import * as yup from 'yup';
import {
  alphabetSpaceRegex,
  bloodGroupRegex,
  phoneRegex,
} from '../utils/validation/consts';
import {AddFamilyMemberFormFields} from '../screens/User/AddFamilyMember/AddFamilyMember.types';
import {AddressObject} from '../services/api/response/app';

yup.setLocale({
  mixed: {
    required: ({path}) => `familyMember.errors.${path}.required`,
  },
  string: {
    matches: ({path}) => `familyMember.errors.${path}.matches`,
  },
});

const objectSchema = yup.object<AddressObject>({
  id: yup.number().required(),
  name: yup.string().required(),
});

export const addFamilyMemberFormSchema = yup
  .object<Record<keyof AddFamilyMemberFormFields, yup.AnySchema>>({
    name: yup.string().trim().required().matches(alphabetSpaceRegex),
    relationShip: objectSchema.required(),
    dob: yup.string().required(),
    occupation: yup.mixed(),
    occupationName: yup.string(),
    bloodGroup: yup.string().trim().required().matches(bloodGroupRegex),
    maritalStatus: objectSchema.required(),
    gender: objectSchema.required(),
    mobileNumber: yup.string().trim().required().matches(phoneRegex),
  })
  .required();
