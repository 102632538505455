import {StyleSheet} from 'react-native';
import {Breakpoints, Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    height: 67,
    maxWidth: '100%',
    flexDirection: 'row',
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    alignItems: 'center',
    backgroundColor: Colors.white,
    gap: 15,
    paddingHorizontal: 20,
  },
  webContainer: {
    minHeight: 80,
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
    gap: 15,
    paddingHorizontal: 16,
  },
  rowView: {
    flexDirection: 'row',
    flex: 1,
    gap: 20,
    maxWidth: '100%',
    alignItems: 'center',
    height: '100%',
  },
  name: {
    // flex: 1,
    fontFamily: FontFamily.PoppinsSemiBold,
    fontSize: FontSize.h5,
    color: Colors.primary,
  },
  leftComponentButton: {
    paddingHorizontal: 10,
    maxWidth: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 0,
  },
  rightComponentButton: {
    paddingHorizontal: 10,
    maxWidth: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 0,
  },
  headerTitleButton: {
    padding: 10,
    justifyContent: 'center',
    borderRadius: 10,
  },
  headerTitleActiveButton: {
    padding: 10,
    justifyContent: 'center',
    backgroundColor: Colors.primaryBackground,
    borderRadius: 10,
  },
  headerTitleButtonActiveText: {
    fontFamily: FontFamily.PoppinsMedium,
    fontSize: FontSize.bodyBig,
    color: Colors.primary,
  },
  headerTitleButtonText: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
  },
  logoStyle: {
    maxWidth: 250,
    paddingTop: 5,
    paddingBottom: 5,
  },
  logoContainer: {
    width: 480,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    flexDirection: 'row',
  },
  logoContainer_M_View: {
    width: '100%',
    marginRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  logoTextContainer_M_View: {
    width: '85%',
    fontFamily: FontFamily.PoppinsBold,
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  logoText_M_View: {
    fontFamily: FontFamily.PoppinsItalic,
    width: '100%',
    fontSize: 12,
    color: '#17179b',
    fontWeight: '600',
  },
  logoText: {
    fontFamily: FontFamily.PoppinsItalic,
    fontSize: 20,
    color: '#17179b',
    fontWeight: '600',
  },
  logoTextContainer: {
    maxWidth: '80%',
    height: 80,
    fontFamily: FontFamily.PoppinsBold,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  rowViewMobileWeb: {
    width: '100%',
    flexDirection: 'row',
    gap: 5,
    paddingHorizontal: 20,
    overflow: 'scroll',
  },
  landingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 15,
    // paddingVertical: 15,
    backgroundColor: Colors.white,
    //@ts-ignore
    position: 'fixed',
    width: '100%',
    top: 0,
    maxWidth: Breakpoints.xl,
  },
  landingRowView: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
  },
  loginButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: Colors.primary,
    width: 130,
    borderRadius: 100,
    paddingHorizontal: 10,
  },
  loginText: {
    color: Colors.primary,
    fontSize: FontSize.h6,
  },
  signUpButton: {
    width: 130,
    borderRadius: 100,
    paddingHorizontal: 10,
  },
  signUpButtonText: {
    fontSize: FontSize.h6,
  },
  playStoreIcon: {width: 150, height: 50, resizeMode: 'contain'},
  langSelectionWrapper: {
    width: 200,
    height: 60,
    // borderWidth: 2,
    // position: 'relative',
    // top: 40,
    // left: 50,
    // borderColor: `black`,
    // borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
  },
  languageName: {
    fontSize: 20,
    color: Colors.maroon,
    // textDecorationLine: 'underline',
  },
});
