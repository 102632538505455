import {View, Text, FlatList, Platform, TouchableOpacity} from 'react-native';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {styles} from './ContactUs.styles';
import {Logo} from '../../assets/svg';
import {contactInfoData} from './ContactUs.const';
import {
  Button,
  ContactInfo,
  DropDown,
  InputWithControl,
  Spinner,
} from '../../components';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints} from '../../utils/theme';
import {useContactUsController} from './ContactUs.controller';
import {Control, Controller, FieldValues} from 'react-hook-form';
import {getErrorProps} from '../../utils/validation/validationFunctions';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const ContactUs: React.FC = () => {
  const navigate = useNavigate();
  const {windowWidth} = useDimensionsHook();
  const {
    control,
    categories,
    handleSubmit,
    onSubmit,
    isLoading,
    errors,
    openLinks,
  } = useContactUsController();
  const intl = useIntl();
  const {t} = useTranslation();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;

  return (
    <View style={styles.container}>
      <Spinner visible={isLoading} />
      <View style={styles.headerRowView}>
        <TouchableOpacity
          style={styles.backButton}
          onPress={() =>
            navigate('/', {
              replace: true,
            })
          }>
          <Text style={styles.backText}>
            <FormattedMessage id="misc.back" />
          </Text>
        </TouchableOpacity>
        <View style={styles.logoContainer}>
          <Logo
            style={styles.logoStyle}
            width={isWeb ? 120 : 180}
            height={150}
          />
          <View style={styles.logoTextContainer}>
            <Text style={styles.logoText}>
              श्री समस्त राजस्थान जैन समाज वडोदरा
            </Text>
          </View>
        </View>
      </View>
      <View style={[styles.rowView, {flexDirection: isWeb ? 'row' : 'column'}]}>
        <View style={styles.boxView}>
          <Text style={styles.title}>
            <FormattedMessage id="contactUs.assistYou" />
          </Text>
          <Text style={styles.message}>
            <FormattedMessage id="contactUs.message" />
          </Text>
          <FlatList
            key={isWeb.toString()}
            data={contactInfoData}
            numColumns={isWeb ? 2 : 1}
            columnWrapperStyle={isWeb ? {gap: 30} : undefined}
            contentContainerStyle={{gap: 30}}
            renderItem={({item}) => (
              <ContactInfo
                {...item}
                onPress={() => {
                  if (item?.link) {
                    openLinks(item?.link);
                  }
                }}
              />
            )}
          />
        </View>
        <View style={styles.boxView}>
          <Text style={styles.inquiryForm}>
            <FormattedMessage id="contactUs.inquiryForm" />
          </Text>
          <Text style={styles.inquiryFormMessage}>
            <FormattedMessage id="contactUs.inquiryFormMessage" />
          </Text>
          <View
            style={[
              styles.rowInputView,
              {flexDirection: isWeb ? 'row' : 'column'},
            ]}>
            <InputWithControl
              control={control as unknown as Control<FieldValues>}
              name="phoneNumber"
              containerStyle={{flex: 1}}
              maxLength={10}
              keyboardType="phone-pad"
              label={t('misc.phoneNumber')}
              {...getErrorProps(intl, errors.phoneNumber)}
            />
            <InputWithControl
              control={control as unknown as Control<FieldValues>}
              name="name"
              containerStyle={{flex: 1}}
              label={t('familyMember.name')}
              {...getErrorProps(intl, errors.name)}
            />
          </View>
          <View
            style={[
              styles.rowInputView,
              {flexDirection: isWeb ? 'row' : 'column'},
            ]}>
            <InputWithControl
              control={control as unknown as Control<FieldValues>}
              name="email"
              containerStyle={{flex: 1}}
              label={t('profile.email') + '*'}
              {...getErrorProps(intl, errors.email)}
            />
            <Controller
              control={control}
              name="category"
              render={({field: {value, onChange}}) => (
                <DropDown
                  data={categories}
                  displayKey="name"
                  value={value?.name}
                  onSelectItem={onChange}
                  containerStyle={{flex: 1}}
                  {...getErrorProps(intl, errors.category?.name)}
                  label={t('contactUs.category') + '*'}
                />
              )}
            />
          </View>
          <InputWithControl
            control={control as unknown as Control<FieldValues>}
            name="message"
            label={t('contactUs.messagefield')}
            multiline
            inputViewStyle={{height: 150}}
            {...getErrorProps(intl, errors.message)}
          />
          <Button name={t('misc.submit')} onPress={handleSubmit(onSubmit)} />
        </View>
      </View>
      <Text style={styles.copyRights}>
        <FormattedMessage id="misc.copyRights" />
      </Text>
    </View>
  );
};

export default ContactUs;
