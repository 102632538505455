import {View, ScrollView, Platform, Text} from 'react-native';
import React from 'react';
import useDynamicStyles from './ProfileDetail.styles';
import {useProfileDetail} from './ProfileDetail.controller';
import {BackArrow, WhatsApp, Email} from '../../../assets/svg';
import {Avatar, Header, ProfileInfoTile, Spinner} from '../../../components';
import {ApiRoutes} from '../../../services/api/path';
import {FormattedMessage} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import useDimensionsHook from '../../../hooks/useDimensions';
import {Breakpoints} from '../../../utils/theme';
import {useAppSelector} from '../../../services/redux/hook';
import {NavigationNames} from '../../../utils/routeNames';
import {useTranslation} from 'react-i18next';
import {useLoginController} from '../../Login/Login.controller';
import moment from 'moment';

const ProflleDetail: React.FC = () => {
  const {windowWidth} = useDimensionsHook();
  const style = useDynamicStyles();
  const {user} = useAppSelector(state => state.userReducer);
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const {state} = useLocation();
  const {profile, isLoading} = useProfileDetail(state?.profile);
  const isLoginUser = user?.uId === state?.profile?.loginId;
  const navigation = useNavigate();
  const {t} = useTranslation();
  const {openWhatsApp, openEmail} = useLoginController({});

  const onClosePress = (): void => {
    navigation(-1);
  };

  const onEditPress = (): void => {
    if (isLoginUser) {
      navigation(NavigationNames.EditProfile);
    }
  };

  return (
    <View style={style.container}>
      <Spinner visible={isLoading} />
      <Header
        rightComponent={
          isLoginUser ? (
            <Text style={style.editButtonText}>
              <FormattedMessage id={t('misc.edit')} />
            </Text>
          ) : (
            <></>
          )
        }
        rightComponentPress={onEditPress}
        leftComponent={<BackArrow width={20} height={20} />}
        leftComponentPress={onClosePress}
        title={<FormattedMessage id={t('profile.profileDetails')} />}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={style.scrollView}>
        {profile && (
          <View>
            <View
              style={[
                style.rowView,
                {flexDirection: isWeb ? 'row' : 'column'},
              ]}>
              <View style={style.boxView}>
                <Avatar
                  image={
                    profile?.profileImage
                      ? {
                          uri: `${
                            ApiRoutes.BaseURL
                          }${profile?.profileImage?.substring(1)}`,
                        }
                      : 0
                  }
                  firstName={profile?.firstName}
                  middleName={profile?.middleName}
                  lastName={profile?.lastName}
                  size="large"
                  containerStyle={[
                    style.image,
                    {width: isWeb ? 150 : 80, height: isWeb ? 150 : 80},
                  ]}
                />
                <Text style={style.nameText}>{`${profile.firstName || ''} ${
                  profile.middleName || ''
                } ${profile.lastName || ''}`}</Text>
              </View>
              <View style={style.boxView}>
                <Text style={style.title}>
                  <FormattedMessage id={t('profile.personalInfo')} />
                </Text>
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.fatherName')} />}
                  value={profile.fatherName}
                  containerStyle={style.profileTile}
                />

                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.email')} />}
                  icon={profile.email ? <Email width={15} height={15} /> : ''}
                  onPress={openEmail}
                  value={profile.email}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.dob')} />}
                  value={moment(profile.dob).format('DD MM YYYY')}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.bloodGroup')} />}
                  value={profile.bloodGroup}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.whatsAppNumber')} />}
                  icon={
                    profile.whatsAppNo ? (
                      <WhatsApp width={15} height={15} />
                    ) : (
                      ''
                    )
                  }
                  onPress={openWhatsApp}
                  value={profile.whatsAppNo}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.mobileNumber')} />}
                  value={profile.mobileNo}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={
                    <FormattedMessage id={t('profile.gotraWithoutAsterisk')} />
                  }
                  value={profile.gotra}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={
                    <FormattedMessage id={t('profile.panthWithoutAsterisk')} />
                  }
                  value={profile.panthName}
                  containerStyle={style.profileTile}
                />
              </View>
            </View>
            <View
              style={[
                style.rowView,
                {
                  flexDirection: isWeb ? 'row' : 'column',
                  marginTop: isWeb ? 40 : 20,
                },
              ]}>
              <View style={style.boxView}>
                <Text style={[style.title, {marginTop: 20}]}>
                  <FormattedMessage id={t('profile.contactDetails')} />
                </Text>
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.addressLine1')} />}
                  value={profile.homeAddressLine1}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.addressLine2')} />}
                  value={profile.homeAddressLine2}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.landmark')} />}
                  value={profile.homeAddressLandMark}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.state')} />}
                  value={profile.homeState[0]?.name}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.district')} />}
                  value={profile.homeDistrict[0]?.name}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.city')} />}
                  value={profile.homeCity[0]?.name}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.area')} />}
                  value={profile.homeArea?.[0]?.name}
                  containerStyle={style.profileTile}
                />
              </View>
              <View style={style.boxView}>
                <Text style={[style.title, {marginTop: 20}]}>
                  <FormattedMessage id={t('profile.officeDetail')} />
                </Text>
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.addressLine1')} />}
                  value={profile.officeAddressLine1}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.addressLine2')} />}
                  value={profile.officeAddressLine2}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.landmark')} />}
                  value={profile.officeAddressLandMark}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.state')} />}
                  value={profile.officeState[0]?.name}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.district')} />}
                  value={profile.officeDistrict[0]?.name}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.city')} />}
                  value={profile.officeCity[0]?.name}
                  containerStyle={style.profileTile}
                />
                <ProfileInfoTile
                  title={<FormattedMessage id={t('profile.area')} />}
                  value={profile.officeArea?.[0]?.name}
                  containerStyle={style.profileTile}
                />
              </View>
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default ProflleDetail;
