import {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import axios from '../../../services/api/api';
import {ApiRoutes} from '../../../services/api/path';
import {AllMembersResponse} from '../../../services/api/response/community';
import {allMembersAction} from '../../../services/redux/community/community.action';
import {AllMembersController, LoadingState} from './AllMembers.types';

export const useAllMembersController = (): AllMembersController => {
  const dispatch = useAppDispatch();
  const {allMembers} = useAppSelector(state => state.communityReducer);
  const [loading, setLoading] = useState<LoadingState>({
    fetching: false,
    refreshing: false,
  });

  const fetchAllMembers = useCallback(
    async (isRefreshing?: boolean) => {
      try {
        setLoading({
          fetching: allMembers.length === 0,
          refreshing: !!isRefreshing,
        });
        const {data} = await axios.post<AllMembersResponse>(
          ApiRoutes.AllMembers,
        );
        dispatch(allMembersAction(data.members));
        setLoading({
          fetching: false,
          refreshing: false,
        });
      } catch (e) {
        console.error(e);
        setLoading({
          fetching: false,
          refreshing: false,
        });
      }
    },
    [allMembers, dispatch],
  );

  useEffect(() => {
    fetchAllMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {allMembers, loading, fetchAllMembers};
};
