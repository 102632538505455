import {Text, Image, Platform, TouchableOpacity, View} from 'react-native';
import React from 'react';
import {MatrimonialProfileInfoProps} from './MatrimonialProfileInfo.types';
import {style} from './MatrimonialProfileInfo.style';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints, Colors} from '../../utils/theme';
import {ApiRoutes} from '../../services/api/path';
import {User} from '../../assets/svg';

const MatrimonialProfileInfo: React.FC<MatrimonialProfileInfoProps> = ({
  name,
  practice,
  image,
  onPress,
}) => {
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={onPress}
      style={[style.container, {maxWidth: isWeb ? 300 : 'auto'}]}>
      {image ? (
        <Image
          style={style.profileImage}
          source={{uri: ApiRoutes.BaseURL + image?.substring(1)}}
        />
      ) : (
        <View style={style.profileEmptyImage}>
          <User width="50%" height="50%" fill={Colors.lightGray} />
        </View>
      )}
      <Text style={style.name}>{name}</Text>
      <Text style={style.descriprion}>{practice}</Text>
    </TouchableOpacity>
  );
};

export default MatrimonialProfileInfo;
