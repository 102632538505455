import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect} from 'react';
import {Linking, Platform} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {useNavigation} from '@react-navigation/native';
import {useModal} from '../../setup/providers/modalContext/ModalContext';
import {useTranslation} from 'react-i18next';
import axios from '../../services/api/api';
import {ApiRoutes, SupportURLs} from '../../services/api/path';
import {phoneRegex} from '../../utils/validation/consts';
import {loginFormSchema} from '../../validationSchemas/login';
import {
  LoginController,
  LoginControllerProps,
  LoginFormFields,
  LoginScreenNavigationProp,
} from './Login.types';
import {CheckUserExistResponse} from '../../services/api/response/user';
import {User, Verification} from '../../services/api/response/user';
import {userInfoAction} from '../../services/redux/user/user.action';
import {useAppDispatch} from '../../services/redux/hook';
import {NavigationNames} from '../../utils/routeNames';

export const useLoginController = (
  props: LoginControllerProps,
): LoginController => {
  const isWeb = Platform.OS === 'web';
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = isWeb ? useNavigate() : undefined;
  const navigation = useNavigation<LoginScreenNavigationProp>();
  const {openModal, closeModal} = useModal();
  const {t} = useTranslation();
  const appDispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm<LoginFormFields>({
    resolver: yupResolver(loginFormSchema),
    mode: 'onChange',
    defaultValues: {
      isLoading: false,
      newUserModal: false,
      hasAccount: false,
    },
  });

  const {isLoading, newUserModal, mobileNumber, hasAccount} = watch();

  useEffect(() => {
    if (mobileNumber) {
      setValue('mobileNumber', mobileNumber?.replace(/[^0-9]/g, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumber, setValue]);

  const checkUserNumberExist = useCallback(async () => {
    setValue('isLoading', true);
    try {
      const response = await axios.post<CheckUserExistResponse>(
        ApiRoutes.CheckUserExist,
        {
          requestById: 9,
          MobileNo: mobileNumber,
        },
      );

      const {isExistUser, isValid} = response.data;

      if (isExistUser && isValid) {
        setValue('hasAccount', true);
      } else {
        openModal({
          title: 'Login Failed !',
          message: t('misc.userNotFound'),
          positiveButtonProps: {
            name: t('misc.ok'),
            onPress: () => {
              setValue('hasAccount', false);
              setValue('password', '');
              closeModal();
            },
          },
          ...(isWeb
            ? {
                negativeButtonProps: {
                  name: t('login.signUp'),
                  onPress: () => {
                    closeModal();
                    if (navigate) {
                      navigate(NavigationNames.SignUp);
                    }
                  },
                },
              }
            : {
                negativeButtonProps: {
                  name: t('login.signUp'),
                  onPress: () => {
                    closeModal();
                    if (navigation) {
                      navigation.navigate(NavigationNames.SignUp);
                    }
                  },
                },
              }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setValue('isLoading', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumber, openModal, setValue, t, closeModal, navigate, navigation]);

  useEffect(() => {
    if (
      String(mobileNumber).length === 10 &&
      phoneRegex.test(String(mobileNumber))
    ) {
      checkUserNumberExist();
    } else {
      setValue('hasAccount', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumber]);

  useEffect(() => {
    if (props.isNewUserRegister) {
      setValue('newUserModal', true);
    }
  }, [props.isNewUserRegister, setValue]);

  const onSubmit = async (
    data: LoginFormFields,
    onSuccess: () => void,
    profileNotCompleted: () => void,
  ) => {
    try {
      setValue('isLoading', true);
      const {data: response} = await axios.post<any>(ApiRoutes.AuthorisedUser, {
        UserName: data.mobileNumber,
        Password: data.password,
        RequestById: 9,
      });

      const authorizedUser = JSON.parse(response.authorizedUser);

      setValue('isLoading', false);
      if (authorizedUser.UId) {
        fetchUserDetails(authorizedUser, onSuccess, profileNotCompleted);
        return;
      } else if (response.isExistUser && !response.isVerified) {
        setValue('newUserModal', true);
        return;
      } else if (!response.isExistUser) {
        openModal({
          title: t('login.loginFailed'),
          message: response.message,
        });
      }
    } catch (e) {
      setValue('isLoading', false);
      openModal({
        title: t('misc.somethingWentWrong'),
        message: t('misc.somethingWentWrong'),
      });
    }
  };

  const fetchUserDetails = async (
    verification: Verification,
    onSuccess: () => void,
    profileNotCompleted: () => void,
  ) => {
    try {
      const {data} = await axios.post(ApiRoutes.UserDetails, {
        UId: verification.UId,
        ApiSecret: verification.ApiSecret,
        LoginId: verification.UId,
      });
      setValue('isLoading', false);
      if (data.isAuthorisedUser) {
        const response = data.userDetails[0] as User;
        const requiredFields = [
          'firstName',
          'lastName',
          'mobileNo',
          'occupation',
          'gotra',
          'panthName',
          'homeAddressLine1',
          'homeAddressLine2',
          'homeAreaId',
        ];

        const isProfileCompleted = requiredFields.some(
          field =>
            response[field as keyof User] === undefined ||
            response[field as keyof User] === null ||
            response[field as keyof User] === '',
        );
        appDispatch(
          userInfoAction({
            ...response,
            ...verification,
            hasProfileCompleted: !isProfileCompleted,
          }),
        );
        if (!isProfileCompleted) {
          onSuccess();
        } else {
          profileNotCompleted();
        }
      } else {
        openModal({
          title: t('verification.verificationFailed'),
          message: data.message,
        });
        profileNotCompleted();
      }
    } catch (e) {
      setValue('isLoading', false);
    }
  };

  const openWhatsApp = async () => {
    const supported = await Linking.canOpenURL(
      Platform.OS === 'web' ? SupportURLs.WhatsAppWeb : SupportURLs.WhatsApp,
    );

    if (supported) {
      await Linking.openURL(
        Platform.OS === 'web' ? SupportURLs.WhatsAppWeb : SupportURLs.WhatsApp,
      );
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  const openEmail = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.Email);

    if (supported) {
      await Linking.openURL(SupportURLs.Email);
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  return {
    control,
    onSubmit,
    handleSubmit,
    errors,
    isLoading,
    setValue,
    newUserModal,
    hasAccount,
    openWhatsApp,
    openEmail,
    watch,
  };
};
