import {View, FlatList, RefreshControl, TextInput} from 'react-native';
import React, {useState} from 'react';
import {style} from './AllMembers.styles';
import {useDimensions} from '../../../hooks/useDimensions';
import {Breakpoints} from '../../../utils/theme';
import {EmptyComponent, MemberInfo, Spinner} from '../../../components';
import {useAllMembersController} from './AllMembers.controller';
import {ApiRoutes} from '../../../services/api/path';
import {CommunityMembers} from '../../../assets/svg';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../../utils/routeNames';
import {BoardMember} from '../../../services/api/response/community';
import {useTranslation} from 'react-i18next';

const AllMembers: React.FC = () => {
  const {windowWidth} = useDimensions();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const isMobile =
    windowWidth <= Breakpoints.md && windowWidth >= Breakpoints.xs;
  const columnCount = isMobile ? 2 : Math.floor(windowWidth / 300);
  const {allMembers, loading, fetchAllMembers} = useAllMembersController();

  const formatDateString = (date: string | Date | null | undefined): string => {
    if (!date) return '';

    const dateObj = typeof date === 'string' ? new Date(date) : date;
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) return '';

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const filteredMembers = allMembers
    .filter(member => {
      const formattedDOB = formatDateString(member.dob);
      const lowerCaseQuery = searchQuery.toLowerCase();

      return (
        member.name.toLowerCase().includes(lowerCaseQuery) ||
        formattedDOB.includes(lowerCaseQuery) ||
        member.bloodGroup?.toLowerCase().includes(lowerCaseQuery) ||
        member.position.toLowerCase().includes(lowerCaseQuery)
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const onProfilePress = (item: BoardMember): void => {
    navigate(NavigationNames.ProfileDetail, {
      state: {
        profile: item,
      },
    });
  };

  return (
    <View style={style.container}>
      <Spinner visible={loading.fetching} />
      <TextInput
        style={style.input}
        placeholder={t('community.searchQuery')}
        value={searchQuery}
        onChangeText={text => setSearchQuery(text)}
      />
      <FlatList
        key={columnCount}
        data={filteredMembers}
        numColumns={columnCount}
        keyExtractor={item => item.loginId.toString()}
        renderItem={({item}) => (
          <MemberInfo
            {...item}
            image={
              item?.image
                ? {
                    uri: `${ApiRoutes.BaseURL}${item?.image?.substring(1)}`,
                  }
                : 0
            }
            onPress={() => onProfilePress(item)}
          />
        )}
        columnWrapperStyle={style.columnWrapperStyle}
        contentContainerStyle={style.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={loading.refreshing}
            onRefresh={() => fetchAllMembers(true)}
          />
        }
        ListEmptyComponent={() => (
          <EmptyComponent
            icon={<CommunityMembers width={250} height={250} />}
            isFetching={loading.fetching}
            message={<FormattedMessage id="community.noMembers" />}
          />
        )}
      />
    </View>
  );
};

export default AllMembers;
