import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  Text,
} from 'react-native';
import React from 'react';
import {style} from './SignUp.styles';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  Avatar,
  Button,
  CheckBox,
  InputWithControl as Input,
  Spinner,
} from '../../components';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints} from '../../utils/theme';
import {useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../utils/routeNames';
import {useSignUpController} from './SignUp.controller';
import {Control, Controller, FieldValues} from 'react-hook-form';
import {getErrorProps} from '../../utils/validation/validationFunctions';
import {Logo} from '../../assets/svg';
import {useTranslation} from 'react-i18next';
import DatePicker from '../../components/DatePicker/DatePicker.web';

const SignUp: React.FC = () => {
  const intl = useIntl();
  const {windowWidth} = useDimensionsHook();
  const {t} = useTranslation();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    onSubmit,
    errors,
    isLoading,
    setValue,
    openPrivacyPolicy,
    openTeamsAndService,
  } = useSignUpController();

  const onSuccessRegister = () => {
    navigate(NavigationNames.Login, {
      replace: true,
      state: {isNewUserRegister: true},
    });
  };

  return (
    <View style={[style.container, {alignItems: isWeb ? 'center' : 'stretch'}]}>
      <Spinner visible={isLoading} />
      <View
        style={[
          style.headerRowView,
          {
            minWidth: isWeb ? Breakpoints.md : 'auto',
            maxWidth: Breakpoints.md,
          },
        ]}>
        <TouchableOpacity
          style={style.backButton}
          onPress={() =>
            navigate(NavigationNames.Login, {
              replace: true,
            })
          }>
          <Text style={style.backText}>
            <FormattedMessage id={t('misc.back')} />
          </Text>
        </TouchableOpacity>
        <Logo style={style.logoStyle} width={isWeb ? 120 : 180} height={150} />
        <View style={style.logoTextContainer}>
          <Text style={style.logoText}>
            श्री समस्त राजस्थान जैन समाज वडोदरा{' '}
          </Text>
        </View>
      </View>
      <KeyboardAvoidingView
        style={style.keyBoardView}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={style.scrollView}>
          <View
            style={[
              style.contentContainer,
              {
                minWidth: isWeb ? Breakpoints.md : 'auto',
                maxWidth: Breakpoints.md,
              },
            ]}>
            <Text style={style.headerTitle}>
              <FormattedMessage id={t('signUp.createAccount')} />
            </Text>
            <Text style={style.description}>
              <FormattedMessage id={t('signUp.signUpMessage')} />
            </Text>
            <Avatar
              containerStyle={{alignSelf: 'center'}}
              image={0}
              size="large"
              editable
              onIconPress={image => setValue('profileImage', image)}
            />
            <View style={style.nameRowView}>
              <Input
                autoFocus={isWeb}
                control={control as unknown as Control<FieldValues>}
                name="firstName"
                label={t('profile.firstName')}
                containerStyle={style.inputContainer}
                {...getErrorProps(intl, errors.firstName)}
                maxLength={30}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccessRegister),
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="middleName"
                {...getErrorProps(intl, errors.middleName)}
                label={t('profile.middleName')}
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccessRegister),
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="lastName"
                {...getErrorProps(intl, errors.lastName)}
                label={t('profile.lastName')}
                containerStyle={style.inputContainer}
                maxLength={30}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccessRegister),
                )}
              />
            </View>
            <Input
              control={control as unknown as Control<FieldValues>}
              name="email"
              {...getErrorProps(intl, errors.email)}
              label={t('profile.email')}
              containerStyle={style.inputContainer}
              keyboardType="email-address"
              onSubmitEditing={handleSubmit(data =>
                onSubmit(data, onSuccessRegister),
              )}
            />
            <Input
              control={control as unknown as Control<FieldValues>}
              name="mobileNumber"
              {...getErrorProps(intl, errors.mobileNumber)}
              maxLength={10}
              label={t('misc.phoneNumber')}
              containerStyle={style.inputContainer}
              keyboardType="phone-pad"
              returnKeyType="done"
              onSubmitEditing={handleSubmit(data =>
                onSubmit(data, onSuccessRegister),
              )}
            />
            <Input
              control={control as unknown as Control<FieldValues>}
              name="whatsAppNumber"
              {...getErrorProps(intl, errors.whatsAppNumber)}
              maxLength={10}
              label={t('profile.whatsAppNumber')}
              containerStyle={style.inputContainer}
              keyboardType="phone-pad"
              returnKeyType="done"
            />
            <Controller
              control={control}
              name="dob"
              render={({field: {onChange, value}}) => (
                <DatePicker
                  label={t('profile.dateOfBirth')}
                  containerStyle={style.inputContainer}
                  {...getErrorProps(intl, errors.dob)}
                  onChange={onChange}
                  value={value}
                  placeHolder="DD-MM-YYYY"
                  mode="date"
                  maximumDate={new Date()}
                />
              )}
            />

            <Input
              control={control as unknown as Control<FieldValues>}
              name="bloodGroup"
              {...getErrorProps(intl, errors.bloodGroup)}
              label={intl.formatMessage({id: t('profile.bloodGroup')})}
              containerStyle={style.inputContainer}
            />
            <Controller
              control={control as unknown as Control<FieldValues>}
              name="acceptTandC"
              render={({field: {onChange, value}}) => (
                <View style={style.privacyContainer}>
                  <CheckBox
                    onPress={() => onChange(!value)}
                    style={style.checkBox}
                    checked={value}
                  />
                  <Text>
                    <FormattedMessage id={t('signUp.byCreatingAccount')} />
                    <TouchableOpacity onPress={openPrivacyPolicy}>
                      <Text style={style.privacyPolicy}>
                        <FormattedMessage id={t('signUp.privacyPolicy')} />
                      </Text>
                    </TouchableOpacity>{' '}
                    <FormattedMessage id={t('signUp.and')} />
                    <TouchableOpacity onPress={openTeamsAndService}>
                      <Text style={style.privacyPolicy}>
                        <FormattedMessage id={t('signUp.termsOfService')} />
                      </Text>
                    </TouchableOpacity>
                    .
                  </Text>
                </View>
              )}
            />
            <Button
              style={style.submitButton}
              name={<FormattedMessage id={t('misc.submit')} />}
              onPress={handleSubmit(data => onSubmit(data, onSuccessRegister))}
            />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
};

export default SignUp;
